import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import Image from '../components/Image';
import Button from '../components/Button';
import Icon from '../components/Icon';
import '../styles/styles.scss';
import '../styles/pages/_home.scss';

const FamilyActivitiesPage = () => (
  <Layout title="Family Activities">
    <Section className="about-mission">
      <Container>
        <h1 className="h1">Family Activity</h1>
        <Image
          filename="Family-Activity-image-hero@2x.jpg"
          className="about__hero-image"
        />
        <h2 className="h2 mt-1">COMMUNITY EDUCATION AND ACTION</h2>
        <p>
          Social determinants of health (SDoH) can have an immense impact on the
          health and well-being of individuals and whole neighborhoods. Explore
          your neighborhood with your child to see what may be affecting the
          overall health of your neighbors and your own family.
        </p>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row className="mb-0">
          <Column size={12} className="spm__column-name">
            Health Inequity
          </Column>
        </Row>
        <Row className="spm__row-image-container">
          <Column size={6} className="pl-0 pr-0">
            <Image filename="Family-Activity-image-getstarted@2x.jpg" />
          </Column>
          <Column size={6} className="spm__row-text-container">
            <p className="mb-0">FAMILY ACTIVITY</p>
            <h2 className="spm__header-text">Community Education and Action</h2>
            <p className="spm__desc-text">
              After completing an interactive module together, take a walk with
              your child and see what social determinants of health you can
              identify in your neighborhood. What actions can you take to
              address and raise awareness of the SDoHs you identify in your
              community?
            </p>
            <div className="mb-1">
              <Button
                to="/pdfs/Takeda-BetterHealth-FamilyActivity-CommunityEdAndAction-V2.0.pdf"
                isExternal
                target="_blank"
                style={{ fontSize: '1rem' }}
              >
                <Icon name="download" marginRight />
                Family Activity
              </Button>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default FamilyActivitiesPage;
